import { Component } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import PhotoAlbum from "react-photo-album";

// import { images} from "./images";
import React from "react";
import "@fontsource/montserrat";
// import styled from "styled-components";
import "../styles.css"
const imagesHelper = (startIndex, endIndex, endIndexPortrait, url, arr) => {
  for (let index = startIndex; index <= endIndex; index++) {
    arr.push({
      // src: "/images/" + url + '/JPEG1200/MR' + index + ".jpg",
      // original: "/images/" + url + '/MR' + index + ".jpg",
      title: '',
      width: 1200,
      height: 800,
      srcSet: [
        // {src: "/images/" + url + '/JPEG1200/MR' + index + ".jpg", width: 2400, height: 1600},
        { src: "/images/" + url + '/JPEG1200/MR' + index + ".jpg", width: 1200, height: 800 },
        { src: "/images/" + url + '/JPEG800/MR' + index + ".jpg", width: 800, height: 500 },
        { src: "/images/" + url + '/JPEG600/MR' + index + ".jpg", width: 600, height: 400 },
        { src: "/images/" + url + '/JPEG400/MR' + index + ".jpg", width: 400, height: 266 },
        { src: "/images/" + url + '/JPEG200/MR' + index + ".jpg", width: 200, height: 133 }
      ],
    });
  }
  for (let index = startIndex; index <= endIndexPortrait; index++) {
    arr.push({
      // src: "/images/" + url + '/portrait/JPEG1200/MR' + index + ".jpg",
      // original: "/images/" + url + '/portrait/MR' + index + ".jpg",
      width: 800,
      height: 1200,
      srcSet: [
        // {src: "/images/" + url + '/portrait/JPEG1200/MR' + index + ".jpg", width: 1600, height: 2400},
        { src: "/images/" + url + '/portrait/JPEG1200/MR' + index + ".jpg", width: 800, height: 1200 },
        { src: "/images/" + url + '/portrait/JPEG800/MR' + index + ".jpg", width: 500, height: 800 },
        { src: "/images/" + url + '/portrait/JPEG600/MR' + index + ".jpg", width: 400, height: 600 },
        { src: "/images/" + url + '/portrait/JPEG400/MR' + index + ".jpg", width: 266, height: 400 },
        { src: "/images/" + url + '/portrait/JPEG200/MR' + index + ".jpg", width: 133, height: 200 }
      ]
    });

  }
}



const slides1 = []
const slides2 = []
const slides3 = []
imagesHelper(1, 27, 30, "AleksandraLukasz", slides1);
imagesHelper(1, 40, 19, "MartynaKamil", slides2);
imagesHelper(1, 40, 12, "MonikaMariusz", slides3);

const mainSlides = [slides1[0], slides2[0], slides3[0]]
mainSlides[0].title = 'Aleksandra i Łukasz'
mainSlides[1].title = 'Martyna i Kamil'
mainSlides[2].title = 'Monika i Mariusz'
export default class GalleryPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, index: -1, index2: -1, index3: -1, gallery1: false, gallery2: false, gallery3: false };
    this.buttonInterface = props.backButtonInterface;
    // props.backButtonInterface(true)
  }
  render() {
    return (
      <section id="portfoliofoto">
        {/* {this.state.loading ? (
            <div
              className="loading-gallery"

            >            <div className='camera'></div>
            </div>
          ) : null} */}
          
        <div style={{ backgroundColor: 'black' }}>

          {this.state.gallery1 || this.state.gallery2 || this.state.gallery3 ? <div></div> : <PhotoAlbum
            layout="masonry"
            // style={{ backgroundColor: 'red' }}
            columns={(containerWidth) => {
              if (containerWidth < 400) return 1;
              if (containerWidth < 800) return 1;
              return 2;
            }}
            spacing={0}
            padding={(containerWidth) => {
              if (containerWidth < 400) return 10;
              if (containerWidth < 800) return 30;
              return 40;
            }}
            photos={mainSlides}
            onClick={({ index: current }) => {
              switch (current) {
                case 0:
                  this.setState({ gallery1: true })

                  this.buttonInterface(true)
                  break;
                case 1:
                  this.setState({ gallery2: true })
                  this.buttonInterface(true)

                  break;
                case 2:
                  this.setState({ gallery3: true })
                  this.buttonInterface(true)

                  break;

                default:
                  break;
              }
            }}
            renderPhoto={({ imageProps: { src, alt, style, ...restImageProps } }) => (
              <div className='wrapper-photo'>
                <div onClick={() => {
                  switch (restImageProps.title) {
                    case 'Aleksandra i Łukasz':
                      this.setState({ gallery1: true })
                      this.buttonInterface(true)


                      break;
                    case 'Martyna i Kamil':
                      this.setState({ gallery2: true })
                      this.buttonInterface(true)



                      break;
                    case 'Monika i Mariusz':
                      this.setState({ gallery3: true })
                      this.buttonInterface(true)


                      break;
                    default:
                      break;
                  }
                }
                } className="quote-text-gallery">{restImageProps.title}</div>

                <img src={src} alt={alt} style={style} onLoad={()=>this.setState({loading: false})} {...restImageProps} />
              </div>

            )}
          />}
          {this.state.gallery1 ? <div><PhotoAlbum
            layout="columns"
            componentsProps={(containerWidth) => ({
              imageProps: { loading: (containerWidth || 0) > 600 ? "eager" : "lazy" },
            })}
            // columns={4}
            spacing={0}
            padding={(containerWidth) => {
              if (containerWidth < 400) return 0;
              if (containerWidth < 800) return 10;
              return 10;
            }}
            photos={slides1}
            onClick={({ index: current }) => {
              this.setState({ index: current })

            }}

          />        <Lightbox
              slides={slides1}
              open={this.state.index >= 0}
              index={this.state.index}
              close={() => this.setState({ index: -1 })}
            />


          </div> : null}
          {this.state.gallery2 ? <div><PhotoAlbum
            layout="columns"
            // columns={1}
            spacing={0}
            padding={(containerWidth) => {
              if (containerWidth < 400) return 0;
              if (containerWidth < 800) return 10;
              return 10;
            }}
            photos={slides2}
            onClick={({ index: current }) => {
              this.setState({ index2: current })

            }}
          />      <Lightbox
              slides={slides2}
              open={this.state.index2 >= 0}
              index={this.state.index2}
              close={() => this.setState({ index2: -1 })}
            /></div> : null}
          {this.state.gallery3 ? <div><PhotoAlbum
            layout="columns"
            // columns={1}
            spacing={0}
            padding={(containerWidth) => {
              if (containerWidth < 400) return 0;
              if (containerWidth < 800) return 10;
              return 10;
            }}
            photos={slides3}
            onClick={({ index: current }) => {
              this.setState({ index3: current })

            }}
          />     <Lightbox
              slides={slides3}
              open={this.state.index3 >= 0}
              index={this.state.index3}
              close={() => this.setState({ index3: -1 })} />
          </div> : null}

        </div>
      </section>

    );
  }
}
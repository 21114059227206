import React from 'react';
import PropTypes from 'prop-types';
import './grid.css'
import './index.css'

const propTypes = {
  highlightColor: PropTypes.string,
  children: PropTypes.node
};

const defaultProps = {
  highlightColor: '#f44336',
};

export default class PricingTable extends React.Component {

  render() {
    const childrenWithProps = React.Children.map(this.props.children,
     (child) => React.cloneElement(child, {
       highlightColor: this.props.highlightColor
     })
    );
    return (
      <div className="react-pricing-table">
        <div className="Grid">
          {childrenWithProps}
        </div>
      </div>
    )
  }
}

PricingTable.propTypes = propTypes;
PricingTable.defaultProps = defaultProps;


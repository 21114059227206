import React, { Component } from "react";
import ReactFbq from 'react-fbq';
import Papa from "papaparse";
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import PricingDetail from './PricingTable/PricingDetail'
import PricingSlot from './PricingTable/PricingSlot'
import PricingTable from './PricingTable/PricingTable'
import parse from 'html-react-parser';

// import pakiety from '../../public/pakiety.json'
export default class PriceListPV extends Component {
    constructor(props) {
        super(props);
        this.state = {pakiet1: [], pakiet2: [], pakiet3: [], pakiet1st: [], pakiet2st: [], pakiet3st: [], prices: ['Wczytywanie...','Wczytywanie...', 'Wczytywanie...']};
    
      }
    componentDidMount() {
        fetch("pakiety_weselne.csv", {
            headers : { 
              'content-type': 'text/csv;charset=UTF-8'
             }
            })
            .catch(function() {
          })
        .then((data) => data.text()).then(data => {
            Papa.parse(data, {
                header: false,
                skipEmptyLines: true,
                complete: function (results) {
                    let pakiet1temp = [];
                    let pakiet2temp = [];
                    let pakiet3temp = [];
                    let pakiet1stemp = [];
                    let pakiet2stemp = [];
                    let pakiet3stemp = [];
                    this.setState({prices: Object.values(results.data[0])},()=>{});
                    results.data.shift();
                    results.data.map((d) => {
                        pakiet1temp.push(Object.values(d)[0]);
                        pakiet2temp.push(Object.values(d)[2]);
                        pakiet3temp.push(Object.values(d)[4]);
                        if (Object.values(d)[1].localeCompare("tak")){
                            pakiet1stemp.push(true);

                        } else {
                            pakiet1stemp.push(false);

                        }
                        if (Object.values(d)[3].localeCompare("tak")){
                            pakiet2stemp.push(true);

                        } else {
                            pakiet2stemp.push(false);

                        }
                        if (Object.values(d)[5].localeCompare("tak")){
                            pakiet3stemp.push(true);

                        } else {
                            pakiet3stemp.push(false);

                        }
                        
                      });
                      const pakiet1final = pakiet1temp.filter((word) => word.localeCompare(""));
                      const pakiet2final = pakiet2temp.filter((word) => word.localeCompare(""));
                      const pakiet3final = pakiet3temp.filter((word) => word.localeCompare(""));
                      this.setState({pakiet1: pakiet1final, pakiet2: pakiet2final, pakiet3: pakiet3final, pakiet1st: pakiet1stemp, pakiet2st: pakiet2stemp, pakiet3st: pakiet3stemp}, ()=> {});
                }.bind(this),
              });
        })
      }
 
  render() {
   
    return (
        <section id="cennikinternal">
<PricingTable  highlightColor='#646464'>
    <PricingSlot  onClick={() =>
    {window.location.replace("#kontakt"); ReactFbq.track({ title: "KontaktWideo" }); }} buttonText='KONTAKT' title='PAKIET 1' priceText={this.state.prices[0]}>
        {this.state.pakiet1.map((data, index) => {
            return <PricingDetail strikethrough={!this.state.pakiet1st[index]}> {parse(data)}</PricingDetail>
            })}
    </PricingSlot>
    <PricingSlot  onClick={() =>
    {window.location.replace("#kontakt"); ReactFbq.track({ title: "KontaktWideo" }); }} buttonText='KONTAKT' title='PAKIET 2' priceText={this.state.prices[2]}>
        {this.state.pakiet2.map((data, index) => {
            return <PricingDetail strikethrough={!this.state.pakiet2st[index]}> {parse(data)}</PricingDetail>
            })}
    </PricingSlot>
    <PricingSlot  onClick={() =>
    {window.location.replace("#kontakt"); ReactFbq.track({ title: "KontaktWideo" }); }} buttonText='KONTAKT' title='PAKIET 3' priceText={this.state.prices[4]}>
    {this.state.pakiet3.map((data, index) => {
            return <PricingDetail strikethrough={!this.state.pakiet3st[index]}> {parse(data)}</PricingDetail>
            })}

    </PricingSlot>
</PricingTable>
</section>)
};
}

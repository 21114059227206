import React from 'react';
import PropTypes from 'prop-types';
import './grid.css'
import './index.css'

const propTypes = {
  strikethrough: PropTypes.bool,
  children: PropTypes.node
};

const defaultProps = {
  strikethrough: false,
};

export default class PricingDetail extends React.Component {

  render() {
    const {strikethrough} = this.props;
    return (
      <div>
        <li className={strikethrough ? "text-line-through" : ""}>{this.props.children}</li>
      </div>
    )
  }
}

PricingDetail.propTypes = propTypes;
PricingDetail.defaultProps = defaultProps;



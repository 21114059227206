import { Responsive, WidthProvider } from "react-grid-layout";
import React from "react";
import "@fontsource/montserrat";
import "../styles.css"
import logo1200 from '../JPEG1200/macius.png'
import logo800 from '../JPEG800/macius.png'
import logo400 from '../JPEG800/macius.png'
import PhotoAlbum from "react-photo-album";
import foto1200 from '../JPEG1200/foto.jpg'
import foto800 from '../JPEG800/foto.jpg'
import foto600 from '../JPEG600/foto.jpg'
// import wideo from '../filmowe.jpg'
import wideo1200 from '../JPEG1200/filmowe.jpg'
import wideo800 from '../JPEG800/filmowe.jpg'
import wideo600 from '../JPEG600/filmowe.jpg'
const ResponsiveGridLayout = WidthProvider(Responsive);
const layout = [
  // { i: "bilbo", x: 0, y: 0, w: 0, h: 0 },

    { i: "blue-eyes-dragon", x: 0, y: 0, w: 1, h: 1 },

    { i: "dark-magician", x: 0, y: 0, w: 1, h: 1 },
    { i: "frodo", x: 0, y: 0, w: 0, h: 0 },


];
const layoutDuzy = [
    { i: "frodo", x: 0, y: 0, w: 2, h: 2 },
    // { i: "dark-magician", x: 2, y: 0, w: 0, h: 0 },
    { i: "blue-eyes-dragon", x: 0, y: 0, w: 0, h: 0 },
    { i: "dark-magician", x: 2, y: 0, w: 2, h: 2 }
];
const layoutSredni = [
    { i: "blue-eyes-dragon", x: 0, y: 0, w: 1, h: 2 },
    // { i: "dark-magician", x: 0, y: 2, w: 0, h: 0 },
    { i: "dark-magician", x: 0, y: 2, w: 1, h: 1 },
    { i: "frodo", x: 0, y: 0, w: 0, h: 0 }
];
const slides =  
    [{
      // width: 160,
      // height: 90,
      title: 'Moje prace - zdjęcia',
      srcSet: [
        { src: foto1200, width: 768, height: 1200 },
        { src: foto800, width: 512, height: 800 },
        { src: foto600, width: 384, height: 600 },
      ]
    },
    {
        // src: wideo,
        // width: 160,
        // height: 90,
        title: 'Moje prace - wideo',
        srcSet: [
          { src: wideo1200, width: 768, height: 1200 },
          { src: wideo800, width: 512, height: 800 },
          { src: wideo600, width: 384, height: 600 },
        ]
      },
]
export const Grid = () => {
  const [showScrollable, setShowScriollable] = React.useState(false)
    return (
        <div className="about-container">
          {/* <div style={{overflow: 'auto'}}> */}
            <ResponsiveGridLayout
                layouts={{ lg: layoutDuzy, md: layoutDuzy, sm: layoutDuzy, xs: layoutSredni, xxs: layout }}
                breakpoints={{ lg: 1200, md: 996, sm: 690, xs: 480, xxs: 0 }}
                cols={{ lg: 4, md: 4, sm: 4, xs: 1, xxs: 1 }}
                rowHeight={240}
                width={1000}
                isDraggable={false}
              onBreakpointChange={(newBreakpoint, newCols) => 
                {
                  if ("xxs" === newBreakpoint) {
                    setShowScriollable(true);
                  } else {
                    setShowScriollable(false);

                  }
                }
                
                }

                // compactType={null}

                autoSize={true}
            >
                <div className="grid-item-wrapper" key="blue-eyes-dragon">
                        <img 
                        srcSet={`${logo400} 400w, ${logo800} 800w, ${logo1200} 1200w`}
                        sizes="(max-width: 400px) 400px, (max-width: 768px) 768px, 1280px"
                        alt=""
                        // src={logo} 
                        className="grid-item" width="800" height="800" />
                        
                </div>
                <div className="grid-item-wrapper" key="frodo" style={{overflow:'hidden'}}>
                    <img 
                        srcSet={`${logo400} 400w, ${logo800} 800w, ${logo1200} 1200w`}
                        sizes="(max-width: 400px) 400px, (max-width: 768px) 768px, 1280px"
                        alt=""
                        // src={logo} 
                        className="grid-item" width="800" height="800" />
                        
                </div>
                {showScrollable ? <div className="grid-item-wrapper" key="dark-magician" style={{overflow: 'scroll'}}>

                    <div className="grid-item-bg">
                                
                                <h1 className="quote-text-about-big">
                                    Kilka słów o mnie...</h1>
                                <h1 className="quote-text-about">
                                    Zajmuję się uwiecznianiem najwspanialszych chwil z Waszego życia.
                                    Wykorzystuję swoje umiejętności żeby wywołać na Waszych twarzach szczery uśmiech.

                                    Zajmuję się filmem odkąd skończyłem 12 lat.
                                    Od tamtej pory nagrywałem wszystko, co mogłem i tak trafiłem do branży ślubnej.
                                    Pierwsze wesele nagrałem w wieku 15 lat (nie wyszło najlepiej).

                                    Od tamtej pory minęło trochę czasu. Przybyło filmów i przede wszystkim doświadczenia.

                                    Jeśli szukacie nowego i świeżego spojrzenia na Waszą historię zapraszam do kontaktu!
                                </h1>
                    </div>
                    
                </div> :
                <div className="grid-item-wrapper" key="dark-magician">

                    <div className="grid-item-bg">
                                
                                <h1 className="quote-text-about-big">
                                    Kilka słów o mnie...</h1>
                                <h1 className="quote-text-about">
                                    Zajmuję się uwiecznianiem najwspanialszych chwil z Waszego życia.
                                    Wykorzystuję swoje umiejętności żeby wywołać na Waszych twarzach szczery uśmiech.

                                    Zajmuję się filmem odkąd skończyłem 12 lat.
                                    Od tamtej pory nagrywałem wszystko, co mogłem i tak trafiłem do branży ślubnej.
                                    Pierwsze wesele nagrałem w wieku 15 lat (nie wyszło najlepiej).

                                    Od tamtej pory minęło trochę czasu. Przybyło filmów i przede wszystkim doświadczenia.

                                    Jeśli szukacie nowego i świeżego spojrzenia na Waszą historię zapraszam do kontaktu!
                                </h1>
                    </div>
                    
                </div>}
            </ResponsiveGridLayout>
            {/* </div> */}
            <PhotoAlbum
          layout="columns"
        //   style={{ backgroundColor: 'red' }}
          columns={2}
          spacing={0}
          padding={(containerWidth) => {
            if (containerWidth < 400) return 0;
            if (containerWidth < 800) return 10;
            return 30;
        }}
          photos={slides}
          onClick={({ index: current }) => {
            switch (current) {
              case 0:
                window.location.replace("#portfoliofoto")
                // setIndex(0)
                break;
              case 1:
                window.location.replace("#portfoliowideo")
                // setIndex2(0)
                break;
              case 2:
                // setIndex3(0)
                break;

              default:
                break;
            }
          }}
          renderPhoto={({ imageProps: { src, alt, style, ...restImageProps } }) => (
            <div className='wrapper-photo'>
              <img src={src} alt={alt} style={style} {...restImageProps} />
              <div onClick={() => 
                
                {switch (restImageProps.title) {
                    case 'Moje prace - zdjęcia':
                        window.location.replace("#portfoliofoto")

                        break;
                    case 'Moje prace - wideo':
                        window.location.replace("#portfoliowideo")

                        break;
                
                    default:
                        break;
                }
}
                
                
                }className="buttons-text">  {restImageProps.title}</div>

            
            </div>

          )}
        />
        </div>
    );
};

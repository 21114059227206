import React, { Component } from 'react';
import "../styles.css";
import ReactFbq from 'react-fbq';

export default class Header extends Component {
    render() {
        return (
            // <React.Fragment>
                
                <header id="home">
                    <nav id="nav-wrap">
                        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                        <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>
                        <ul id="nav" className="nav">
                            <li className="current"><a className="smoothscroll" href="#home">Strona główna</a></li>
                            <li onClick={() =>
    {ReactFbq.track({ title: "Kontakt" }); }}><a href="#kontakt">Kontakt</a></li>
                            <li onClick={() =>
    {ReactFbq.track({ title: "Portfolio" }); }}><a href="#portfoliowideo">Portfolio wideo</a></li>
                            <li onClick={() =>
    {ReactFbq.track({ title: "Portfolio" }); }}><a href="#portfoliofoto">Portfolio foto</a></li>
                            <li onClick={() =>
    {;ReactFbq.track({ title: "Cennik" }); }}><a href="#cennik">Cennik</a></li>

                        </ul>

                    </nav>
                    
                </header>

            // </React.Fragment>
        );
    }
}

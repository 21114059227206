
import React from "react";
import "@fontsource/montserrat";
import "../styles.css"
import { VimeoSlide } from "./About";


export default function App() {    
  
  return (
    <section id="portfoliowideo">
    <div style={{backgroundColor: 'black'}}>
      {/* <EmblaCarousel/> */}
      <div style={{padding: '5%'}}>
      <VimeoSlide  videoId="GrTg7kQCwrA" />
      </div>
      <div style={{padding: '5%'}}>
      <VimeoSlide  videoId="hy12BBbgwyk" />
      </div>
      <div style={{padding: '5%'}}>
      <VimeoSlide  videoId="0vu9cAtQJjo" />
      </div><div style={{padding: '5%'}}>
      <VimeoSlide  videoId="-1n2blcfA00" />
      </div>
      {/* <div style={{padding: '5%'}}>
      <VimeoSlide  videoId="762905383" />
      </div>
      <div style={{padding: '5%'}}>
      <VimeoSlide  videoId="762907521" />
      </div> */}
    </div>
    </section>

  );
}
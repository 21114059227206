import React, { Component } from "react";
import ReactFbq from 'react-fbq';
import Papa from "papaparse";
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

// import {PricingTable, PricingSlot, PricingDetail} from 'react-pricing-table';
import PricingDetail from './PricingTable/PricingDetail'
import PricingSlot from './PricingTable/PricingSlot'
import PricingTable from './PricingTable/PricingTable'
import parse from 'html-react-parser';

export default class PriceList extends Component {
    constructor(props) {
        super(props);
        this.state = {pakiet1: [], pakiet2: [], pakiet1st: [], pakiet2st: [], prices: ['Wczytywanie...','Wczytywanie...']};
    
      }
    componentDidMount() {
        fetch("pakiety_weselne.csv", {
            headers : { 
              'content-type': 'text/csv;charset=UTF-8'
             }
            })
            .catch(function() {
          })
        .then((data) => data.text()).then(data => {
            Papa.parse(data, {
                header: false,
                skipEmptyLines: true,
                complete: function (results) {
                    let pakiet1temp = [];
                    let pakiet2temp = [];
                    let pakiet1stemp = [];
                    let pakiet2stemp = [];
                    this.setState({prices: Object.values(results.data[0])},()=>{});
                    results.data.shift();
                    results.data.map((d) => {
                        pakiet1temp.push(Object.values(d)[6]);
                        pakiet2temp.push(Object.values(d)[8]);
                        if (Object.values(d)[7].localeCompare("tak")){
                            pakiet1stemp.push(true);

                        } else {
                            pakiet1stemp.push(false);

                        }
                        if (Object.values(d)[9].localeCompare("tak")){
                            pakiet2stemp.push(true);

                        } else {
                            pakiet2stemp.push(false);

                        }
                        
                      });
                      const pakiet1final = pakiet1temp.filter((word) => word.localeCompare(""));
                      const pakiet2final = pakiet2temp.filter((word) => word.localeCompare(""));
                      this.setState({pakiet1: pakiet1final, pakiet2: pakiet2final,pakiet1st: pakiet1stemp, pakiet2st: pakiet2stemp}, ()=> {});
                }.bind(this),
              });
        })
      }
 
  render() {
   
    return (
        <section id="pricelistpv">
<PricingTable  highlightColor='#646464'>
    <PricingSlot  onClick={() =>
    {window.location.replace("#kontakt"); ReactFbq.track({ title: "KontaktFW" }); }} buttonText='KONTAKT' title='PAKIET 1' priceText={this.state.prices[6]} shouldShowPriceCut={false} priceCut='7100 zł'
    >
        {this.state.pakiet1.map((data, index) => {
            return <PricingDetail strikethrough={!this.state.pakiet1st[index]}> {parse(data)}</PricingDetail>
            })}
    </PricingSlot>
    <PricingSlot  onClick={() =>
    {window.location.replace("#kontakt"); ReactFbq.track({ title: "KontaktFW" }); }} buttonText='KONTAKT' title='PAKIET 2' priceText={this.state.prices[8]} shouldShowPriceCut={false} priceCut='8600 zł'
    >
        {this.state.pakiet2.map((data, index) => {
            return <PricingDetail strikethrough={!this.state.pakiet2st[index]}> {parse(data)}</PricingDetail>
            })}
    </PricingSlot>
</PricingTable>
</section>)
};
}

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import {
    faPhone
} from "@fortawesome/free-solid-svg-icons"
import "../styles.css";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
export default function SocialFollow() {
    return (
        <div className="social-container">
            <div className='social'>
                <a href="https://www.facebook.com/mgfilmswedding/"
                    >
                    <FontAwesomeIcon icon={faFacebook} size="4x" color="#4968ad" />

                </a>
                <a className="no-hyperlink" href="https://www.facebook.com/mgfilmswedding/"
                    >
                <h2 className="text-contact">/mgfilmswedding</h2>
                </a>

            </div>
            <div className='social'>

                <a href="https://www.instagram.com/mg_films_wedding/"
                    >
                    <FontAwesomeIcon icon={faInstagram} size="4x" color="#C13584" />
                </a>
                <a  className="no-hyperlink" href="https://www.instagram.com/mg_films_wedding/"
                    >
                <h2 className="text-contact">/mg_films_wedding</h2>
                </a>

            </div>
            <div className='social'>

                <a href="tel:+48690183779"
                    >
                    <FontAwesomeIcon icon={faPhone} size="4x" color="#FFFFFF" />
                </a>
                <a className="no-hyperlink" href="tel:+48690183779"
                    >
                <h2 className="text-contact">+48 690 183 779</h2>
                </a>

            </div>
            <div className='social'>

                <a href="mailto:maciej.gabryszuk@gmail.com"
                    >
                    <FontAwesomeIcon icon={faEnvelope} size="4x" color="#FFFFFF" />
                </a>
                <a className="no-hyperlink" href="mailto:maciej.gabryszuk@gmail.com"
                    >
                <h2 className="text-contact">maciej.gabryszuk@gmail.com </h2>
                </a>

            </div>

        </div>
    );
}

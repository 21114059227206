import "./styles.css";
import React, { Component } from 'react';
import Header from './components/Header';
import ContactUs from './components/ContactUs';
import PriceList from './components/PriceList';
import PriceListPV from './components/PriceListPV';
import About from './components/About';
import logo from "./image-removebg-preview.png";
import bg1200 from "./JPEG1200/happy-authentic-candid-couple-in-love-indoor-at-lu-2022-04-09-21-55-45-utc-min.jpg";
import bg800 from "./JPEG800/happy-authentic-candid-couple-in-love-indoor-at-lu-2022-04-09-21-55-45-utc-min.jpg";
import bg21200 from "./JPEG1200/happy-authentic-candid-couple-in-love-indoor-at-lu-2022-04-28-09-31-34-utc-min.jpg";
import bg2800 from "./JPEG800/happy-authentic-candid-couple-in-love-indoor-at-lu-2022-04-28-09-31-34-utc-min.jpg";
import GalleryPortfolio from "./components/GalleryPortfolio";
import { ReactComponent as BackArrow } from './back.svg';
import VideoPortfolio from "./components/VideoPortfolio";
import coverPhoto2400 from "./JPEG2400/MR-27.jpg";
// import coverPhoto1200 from "./JPEG800/MR-27.jpg";
import foto1200 from './JPEG1200/MR55.jpg';
import foto800 from './JPEG800/MR55.jpg';
import wideo1200 from './JPEG1200/MR4.jpg';
import wideo800 from './JPEG800/MR4.jpg';
import { ParallaxProvider, Parallax, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import CookieConsent from "react-cookie-consent";
import ReactFbq from 'react-fbq';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
ReactFbq.initialize({ id: '1168115397461387' });

// For tracking page view
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, showBack: false, width: 0, height: 0, galleryVis: true};
    this.changeChild = React.createRef()
    this.galleryVisible = React.createRef();
    this.portfolioRef = React.createRef();
  // const isVisible = useIsVisible(ref);
  }
  showBack(param) {
    this.setState({ showBack: param, galleryVis: param});
    
  };
  componentDidMount() {
    ReactFbq.pageView();
    document.addEventListener("scroll", this.isInViewport);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.isInViewport);
    window.removeEventListener("resize", this.updateWindowDimensions);

}
scrollToElementTop (targetPosition, offset) {
  offset = offset || 0

  window.scrollTo({
    top: targetPosition,
    behavior: 'smooth'
  })

  return new Promise(resolve => {
    const scrollHandler = () => {
      // console.log(window.scrollY)
      if (window.scrollY === targetPosition) {
        window.removeEventListener('scroll', scrollHandler)
        resolve()
      }
    }
    window.addEventListener('scroll', scrollHandler)
    if (window.scrollY === targetPosition) {
      resolve()
    }
  })

}
updateWindowDimensions = () => {
  this.setState({ width: window.innerWidth, height: window.innerHeight });
};

isInViewport = () => {
  // console.log(this.galleryVisible)
  //get how much pixels left to scrolling our ReactElement
  const top = this.galleryVisible.current.getBoundingClientRect().top;
  const bottom = this.galleryVisible.current.getBoundingClientRect().bottom;
  if(top<this.state.height){
    // console.log("AAAAAAAAAAAAA")
    this.setState({ galleryVis: true })
  } else {
    this.setState({ galleryVis: false })
    // console.log("AAAAAAAAAAAAA")

  }
  if(bottom<this.state.height){
    this.setState({ galleryVis: false })

  }
  
};
  render() {

    return (

      <ParallaxProvider>

        <div className="App">
          {this.state.showBack && this.state.galleryVis ? <div ><Fab
            mainButtonStyles={{ backgroundColor: '#212529' ,  borderRadius: 48 }}
            // actionButtonStyles={{ backgroundColor: '#8e44ad' }}
            style={{bottom: -264,right:0 }}
            // icon={<MdAdd />}
            event={"click"}
            alwaysShowTitle={true} 
            icon={<BackArrow style={{height: 600, width: 45}}/>
            }
            onClick={
              () => {
                this.scrollToElementTop(this.portfolioRef.current.offsetTop+this.state.height, 0)
                    this.changeChild.current.setState({ gallery1: false, gallery2: false, gallery3: false });
                this.setState({ showBack: false })
              }
            }
          >
          </Fab></div> : null}

          {this.state.loading ? (
            <div
              className="loading"

            >            <div className='camera'></div>
            </div>
          ) : (<Header />)
          }
          <ParallaxBanner className='parallax-bgvideo' style={{height: '100vh'}}               onLoad={()=>this.setState({loading: false})}
>
            {/* <ParallaxBannerLayer style={{objectFit: 'none',objectPosition: 'left'}} image={coverPhoto} speed={-20} /> */}
            <ParallaxBannerLayer>
              <div 
              // className="header-image"
              style={{
                backgroundImage: 
              `
                url(${coverPhoto2400})`, 
              backgroundPosition: 'left', 
              backgroundSize: 'cover', 
              position: 'absolute', 
              inset: '-200px 0px', 
              objectFit: 'none',
              objectPosition: 'left center',
              willChange: 'transform',
              transform: 'translateY(-8.39895px)'
              }}
              >

              </div>
            </ParallaxBannerLayer>
            <ParallaxBannerLayer>
              <div className='center-div'>

                <div className='outer-img'>
                  <img src={logo} className="maciek" alt="Logo" />
                </div>



                <div className='text-cover-container'>
                  <h1 className="quote-text">Cześć!

                    Jestem Maciek.</h1>
                  <div className="scrolldown-workaround">
                    <a className="smoothscroll" href="#omnie"><i className="icon-down-circle"></i></a>

                  </div>

                </div>
              </div>
            </ParallaxBannerLayer>
          </ParallaxBanner>
          <Parallax>
            <About />

            <section id="portfolio">


              <ParallaxBanner className='bg-przerywnik' style={{ aspectRatio: '2 / 1' }}>
                <ParallaxBannerLayer 
                // image={wideo} 
                speed={-20} >
                <div 
              style={{
                backgroundImage: 
              `image-set(
                url(${wideo800}) 1x,
                url(${wideo1200}) 2x)`, 
              backgroundPosition: 'center', 
              backgroundSize: 'cover', 
              position: 'absolute', 
              inset: '-200px 0px', 
              objectFit: 'none',
              objectPosition: 'left center',
              willChange: 'transform',
              transform: 'translateY(-138.944165px)'
              }}
              >

              </div>
                  </ParallaxBannerLayer>
                {/* <ParallaxBannerLayer image={wideo} speed={-20} /> */}
                <ParallaxBannerLayer>
                  <div className='center-div'>

                    <h1 className='quote-text-heading'>Portfolio wideo</h1>
                  </div>
                </ParallaxBannerLayer>
              </ParallaxBanner>
              <VideoPortfolio></VideoPortfolio>             
              <div ref={this.portfolioRef}>
              <ParallaxBanner className='bg-przerywnik' style={{ aspectRatio: '2 / 1' }}>
                <ParallaxBannerLayer 
                speed={-20} >
                <div 
              style={{
                backgroundImage: 
              `image-set(
                url(${foto800}) 1x,
                url(${foto1200}) 2x)`, 
              backgroundPosition: 'center', 
              backgroundSize: 'cover', 
              position: 'absolute', 
              inset: '-200px 0px', 
              objectFit: 'none',
              objectPosition: 'left center',
              willChange: 'transform',
              transform: 'translateY(-138.944165px)'
              }}
              >

              </div>
                  </ParallaxBannerLayer>
                <ParallaxBannerLayer>
                  <div className='center-div'>

                    <h1 className='quote-text-heading'>Portfolio foto</h1>
                  </div>
                </ParallaxBannerLayer>
              </ParallaxBanner>
              </div>
              </section>
              <section ref={this.galleryVisible}>
              <GalleryPortfolio backButtonInterface={this.showBack.bind(this)}  ref={this.changeChild}/>
             
            </section>
            <section id="cennik">
              <ParallaxBanner className='bg-przerywnik' style={{ aspectRatio: '2 / 1' }}>
              <ParallaxBannerLayer 
                speed={-20} >
                <div 
              style={{
                backgroundImage: 
              `image-set(
                url(${bg800}) 1x,
                url(${bg1200}) 2x)`, 
              backgroundPosition: 'center', 
              backgroundSize: 'cover', 
              position: 'absolute', 
              inset: '-200px 0px', 
              objectFit: 'none',
              objectPosition: 'left center',
              willChange: 'transform',
              transform: 'translateY(-138.944165px)'
              }}
              >

              </div>
                  </ParallaxBannerLayer>
                <ParallaxBannerLayer>
                  <div className='center-div'>
                    <h1 className='quote-text-heading'>Pakiety wideo</h1>
                  </div>
                </ParallaxBannerLayer>
              </ParallaxBanner>
            </section>
            <PriceList />
            <ParallaxBanner className='bg-przerywnik' style={{ aspectRatio: '2 / 1' }}>
            <ParallaxBannerLayer 
                speed={-20} >
                <div 
              style={{
                backgroundImage: 
              `image-set(
                url(${bg2800}) 1x,
                url(${bg21200}) 2x)`, 
              backgroundPosition: 'center', 
              backgroundSize: 'cover', 
              position: 'absolute', 
              inset: '-200px 0px', 
              objectFit: 'none',
              objectPosition: 'left center',
              willChange: 'transform',
              transform: 'translateY(-138.944165px)'
              }}
              >

              </div>
                  </ParallaxBannerLayer>
              <ParallaxBannerLayer>
                <div className='center-div'>

                  <h1 className='quote-text-heading'>Pakiety foto + wideo</h1>
                </div>
              </ParallaxBannerLayer>
            </ParallaxBanner>
            <PriceListPV />
            <ContactUs />
          </Parallax>
          <CookieConsent
            location="bottom"
            buttonText="Rozumiem"
            cookieName="cookie"
            style={{ background: "#2c2d2d", color: "#FFFFFF", fontSize: "14px" }}
            buttonStyle={{ background: "#1F1F1F", color: "#FFFFFF", fontSize: "13px" }}
            expires={150}
          >
            Strona korzysta z plików cookies do prawidłowego funkcjonowania.
            {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
          </CookieConsent>
        </div>
      </ParallaxProvider>

    );
  }
}
export default App;
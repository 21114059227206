import React, { Component } from "react";
import SocialFollow from "./SocialFollow";
export default class ContactUs extends Component {
    render() {
        return (
            <section id="kontakt">
                {/* <div className="row"> */}
                    {/* <div className="ten columns"> */}
                        <SocialFollow></SocialFollow>
                    {/* </div> */}
                {/* </div> */}

            </section>
        );
    }
}
import React, { Component } from "react";
import "../styles.css"
import YouTube from "@u-wave/react-youtube";
import { Grid } from "./Grid";
// import ReactPlayer from 'react-player/vimeo'
export const VimeoSlide = (props) => {

    return (
       <div className="vimeo-outer">
        {/* <ReactPlayer
        url={`https://vimeo.com/${props.videoId}`}
        controls={true}
        
        
        /> */}
        <YouTube
            className="react-vimeo-player-about"
            video={props.videoId}
            autoplay={false}
            muted={false}
            loop={true}
            responsive={true}
            controls={true}
            background={false}
        />
        </div>
    )
}

export default class About extends Component {
    render() {
        return (
            <section id="omnie">
                    <Grid></Grid>

            </section>
        );
    }
}